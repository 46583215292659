import { createGlobalStyle } from 'styled-components';
import { media } from './media';

const Retail = createGlobalStyle`
    :root {
        --primary-1: #323023;  
        --secondary: #FC8A69;  
    }
    .nav--holder, .footer {
        display: none;
    }
`;

export default Retail;
