import { createGlobalStyle } from 'styled-components';
import { media } from './media';

import roobertMedium from '../assets/fonts/Roobert-Medium.woff';
import roobertMedium2 from '../assets/fonts/Roobert-Medium.woff2';
import roobertRegular from '../assets/fonts/Roobert-Regular.woff';
import roobertRegular2 from '../assets/fonts/Roobert-Regular.woff2';
import roobertBold from '../assets/fonts/Roobert-SemiBold.woff';
import roobertBold2 from '../assets/fonts/Roobert-SemiBold.woff2';

const Typography = createGlobalStyle`

    @font-face {
        font-family: 'Roobert';
        src: url(${roobertMedium2}) format('woff2'),
            url(${roobertMedium}) format('woff');
        font-weight: 500;
        font-style: normal;
        font-display: swap;
    }
    
    @font-face {
        font-family: 'Roobert';
        src: url(${roobertRegular2}) format('woff2'),
            url(${roobertRegular}) format('woff');
        font-weight: normal;
        font-style: normal;
        font-display: swap;
    }
    
    @font-face {
        font-family: 'Roobert';
        src: url(${roobertBold2}) format('woff2'),
            url(${roobertBold}) format('woff');
        font-weight: 600;
        font-style: normal;
        font-display: swap;
    }

    html {
        font-family: 'Roobert', 'Times New Roman', 'Open Sans', 'Helvetica Neue', sans-serif;
        color: var(--grey-6);
    }

    .h1 {
        font-weight: 500;
        font-size: 48px;
        line-height: 90%;
        letter-spacing: -0.01em;
        @media ${media.mdUp} { 
            font-size: 64px;
        }
        @media ${media.lgUp} { 
            font-size: 80px;
        }
    }
    eyebrow, .eyebrow {
        font-weight: 600;
        font-size: 10px;
        line-height: 100%;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        &.line {
            position: relative;
            &::after {
                position: absolute;
                content: '';
                width: 25px;
                height: 1px;
                margin: 5px 0 0 5px;
                background-color: currentColor;
            }
        }
    }
    .h2 {
        font-weight: 500;
        font-size: 40px;
        line-height: 92%;
        letter-spacing: -0.01em;
        @media ${media.mdUp} { 
            font-size: 48px;
        }
        @media ${media.lgUp} { 
            font-size: 60px;
        }
    }
    .h3 {
        font-weight: 500;
        font-size: 24px;
        line-height: 110%;
        letter-spacing: -0.01em;
        @media ${media.mdUp} { 
            font-size: 32px;
        }
        @media ${media.lgUp} { 
            font-size: 32px;
        }
        &.normal {
        font-weight: normal;
        }
    }
    .h4 {
        font-weight: 500;
        font-size: 20px;
        line-height: 110%;
        letter-spacing: -0.01em;
        @media ${media.mdUp} { 
            font-size: 24px;
        }
        @media ${media.lgUp} { 
            font-size: 24px;
        }
    }
    .h5 {
        font-weight: 500;
        font-size: 18px;
        line-height: 110%;
        letter-spacing: 0.01em;
        @media ${media.mdUp} { 
            font-size: 18px;
            line-height: 130%;
        }
        @media ${media.lgUp} { 
            font-size: 18px;
        }
    }
    .h6 {
        font-weight: 500;
        font-size: 16px;
        line-height: 125%;
        letter-spacing: 0.01em;
        @media ${media.mdUp} { 
            font-size: 16px;
        }
        @media ${media.lgUp} { 
            font-size: 16px;
        }
    }
    p {
        margin-bottom: 16px;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        letter-spacing: 0.01em;
        @media ${media.mdUp} { 
            font-size: 16px;
            line-height: 160%;
        }
        @media ${media.lgUp} { 
            font-size: 16px;
        }
    }
    p.small, ul.small {
        margin-bottom: 8px;
        font-size: 14px;
        &.mb-0 {
            margin-bottom: 0;
        }
        a:not(.o-hover):not(.menu--link) {
            border-bottom: 1px solid var(--grey-5);
            &:hover, &:focus {
                border-color: transparent;
            }
        }
    }
    span.small {
        font-size: 12px;
    }
    p.tiny, .eyebrow.tiny, ul.tiny {
        font-size: 8px;
        @media ${media.smUp} { 
            font-size: 10px;
        }
    }
    ul.tiny {
        padding: 0 0 0 14px;
        li {
            list-style: disc;
        }
    }
    ol {
        list-style: decimal;
        padding: 0 0 0 1rem;
        margin: 0 0 2rem;
    }
    .quote {
      font-size: 16px;
      line-height: 160%;
      @media ${media.mdUp} { 
        font-size: 22px;
        line-height: 132%;
      }
    }
    caption, .caption {
        font-weight: 600;
        font-size: 10px;
        line-height: 140%;
        letter-spacing: 0.01em;
        a {
            &:hover, &:focus {
                opacity: 0.9;
            }
        }
    }
    sup {
        font-size: 65%;
    }
    .md {
        font-weight: 500;
    }
    .o-hover {
        &:hover, &:focus {
            opacity: 0.9;
        }
    }
    cta, .cta {
        font-weight: 600;
        font-size: 10px;
        line-height: 140%;
        letter-spacing: 0.04em;
        text-transform: uppercase;
    }
    .stat {
        font-size: 48px;
        line-height: 120%;
        letter-spacing: -0.02em;
        @media ${media.mdUp} { 
            font-size: 72px;
        }
        @media ${media.lgUp} { 
            font-size: 86px;
        }
        span {
            font-weight: 500;
            font-size: 25px;
            line-height: 40px;
            letter-spacing: -0.015em;
            @media ${media.mdUp} { 
                font-size: 31px;
                line-height: 45px;
            }
            &.stat--md {
                position: relative;
                top: -5px;
                font-size: 28px;
                @media ${media.mdUp} { 
                    top: -10px;
                    font-size: 48px;
                }
            }
        }
    }
    
    .ul--specs {
        font-size: 14px;
        line-height: 160%;
        letter-spacing: 0.01em;
        color: var(--grey-5);
        li + li {
            margin-top: 7px;
        }
        li {
            position: relative;
            padding-left: 20px;
            &::before {
                position: absolute;
                top: 7px;
                left: 0;
                content: '';
                width: 9px;
                height: 9px;
                border-radius: 100%;
                background: var(--primary);
                border: 1px solid var(--primary);
            }

            &.open {
                &::before {
                    background: transparent;
                }
            }
        }
        &.color--tertiary {
            li::before {
                background: var(--tertiary);
                border: 1px solid var(--tertiary);
            }
        }
    }
    
    .truncate-overflow {
       display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical; 
      overflow: hidden;
    }
    
    .blog {
        img {
          margin-bottom: 40px !important;
        }
        h2 {
            font-size: 24px !important;
            margin-bottom: 20px;
        }
        p {
            margin-bottom: 20px;
        }
        p + h2 {
            margin-top: 40px !important;
        }
        a {
            color: var(--primary-2);
            &:hover, &:focus {
                opacity: 0.8;
            }
        }
    }
    
    .privacy--content {
        color: var(--grey-5);
        p {
            font-size: 16px;
            line-height: 25px;
            margin-bottom: 20px;
            color: var(--grey-5);
        }
        ol {
            padding-left: 20px;
            li {
                list-style: decimal;
            }
        }
        li + li {
            margin-bottom: 1rem;
        }
        ul {
            padding-left: 20px;
            li {
                list-style: disc;
            }
        }
    }
    
    .first-letter {
        text-transform: lowercase;
        &::first-letter {
          text-transform: uppercase;
        }
    }
`;

export default Typography;
