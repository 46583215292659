import React, { useEffect } from 'react';
import { Link } from 'gatsby';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import logo from '../assets/images/cloudkitchens.svg';
import Close from '../assets/svg/svg--close.inline.svg';
import { media } from '../styles/media';

const FooterDiv = styled.footer`
  .caption--footer {
    &:hover,
    &:focus {
      .caption--shown {
        display: none;
      }
      .caption--hidden {
        display: block;
        opacity: 1;
      }
      @media ${media.lgUp} {
        .caption--shown {
          display: block;
        }
      }
    }
    .caption--shown {
    }
    .caption--hidden {
      display: none;
      transition: all 0.3s ease-in;
      opacity: 0;
      @media ${media.lgUp} {
        display: block;
        border-left: 1px solid var(--grey-4);
      }
    }
  }
`;

const CookieBar = styled.div`
  position: fixed;
  padding-top: 22px;
  padding-bottom: 22px;
  width: 100%;
  bottom: 0;
  z-index: 10;
  background: var(--grey-2);
  transform: translateY(100%);
  transition: all 0.3s ease-in;
  p {
    a {
      text-decoration: underline;
      @media ${media.lgUp} {
        text-decoration: none;
      }
    }
  }
  &.is-active {
    transform: translateY(0);
  }
  .cookie--close {
    @media ${media.xlUp} {
      position: absolute;
      right: 20px;
    }
  }
`;

export default function Footer() {
  // Set Cookie
  function setCookie(name, value, days) {
    let expires = '';
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = `; expires=${date.toUTCString()}`;
    }
    document.cookie = `${name}=${value || ''}${expires}; path=/`;
  }

  // Get Cookie
  function getCookie(name) {
    const nameEQ = `${name}=`;
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }

  useEffect(() => {
    const cookieClose = document.querySelector('.cookie--close');
    const cookieAccept = document.querySelector('.cookie--accept');
    const cookieBar = document.querySelector('#cookie-bar');

    cookieClose.addEventListener('click', function (e) {
      e.preventDefault();
      cookieBar.classList.remove('is-active');
      setCookie('ck-cookie', `/`, 365);
    });

    cookieAccept.addEventListener('click', function (e) {
      e.preventDefault();
      cookieBar.classList.remove('is-active');
      setCookie('ck-cookie', `/`, 365);
    });

    const cookie = getCookie('ck-cookie');
    if (!cookie) {
      cookieBar.classList.add('is-active');
    }
  }, []);

  return (
    <FooterDiv className="footer bg--grey-2">
      <div className="container pt-20 md:pt-24 lg:pt-40 pb-10">
        <div className="grid grid-cols-4 md:grid-cols-8 lg:grid-cols-12 pb-12 md:pb-16 lg:pb-40">
          <div className="col-span-4 md:col-span-2 lg:col-span-3">
            <Link to="/">
              <img src={logo} alt="CloudKitchens" className="db" />
            </Link>
          </div>
          <div className="col-span-2 col-start-1 md:col-start-3 lg:col-start-4 mt-12 md:mt-0">
            <div className="eyebrow mb-8 md:mb-10 color--grey-5">Company</div>
            <ul>
              <li>
                <AniLink
                  cover
                  direction="up"
                  duration={1}
                  bg="#E4E2D4"
                  to="/commercial-kitchens"
                  className="btn--text btn--text--large"
                >
                  Kitchens
                </AniLink>
              </li>
              <li>
                <AniLink
                  cover
                  direction="up"
                  duration={1}
                  bg="#E4E2D4"
                  to="/restaurant-technology"
                  className="btn--text btn--text--large"
                >
                  Technology
                </AniLink>
              </li>
              <li>
                <Link to="/retail" className="btn--text btn--text--large">
                  Retail
                </Link>
              </li>
              <li>
                <AniLink
                  cover
                  direction="up"
                  duration={1}
                  bg="#E4E2D4"
                  to="/partners"
                  className="btn--text btn--text--large"
                >
                  Partners
                </AniLink>
              </li>
              <li>
                <AniLink
                  cover
                  direction="up"
                  duration={1}
                  bg="#E4E2D4"
                  to="/careers"
                  className="btn--text btn--text--large"
                >
                  Careers
                </AniLink>
              </li>
              <li>
                <AniLink
                  cover
                  direction="up"
                  duration={1}
                  bg="#E4E2D4"
                  to="/blog"
                  className="btn--text btn--text--large"
                >
                  Blog
                </AniLink>
              </li>
            </ul>
          </div>
          <div className="col-span-2 col-start-3 md:col-start-5 lg:col-start-6 mt-12 md:mt-0">
            <div className="eyebrow mb-8 md:mb-10 color--grey-5">
              Contact Us
            </div>
            <ul>
              <li>
                <a
                  href="https://www.instagram.com/cloudkitchensusa"
                  target="_blank"
                  rel="noreferrer"
                  className="btn--text btn--text--large"
                >
                  Instagram
                </a>
              </li>
              <li>
                <a
                  href="mailto:sales@cloudkitchens.com"
                  className="btn--text btn--text--large"
                >
                  Email
                </a>
              </li>
              <li>
                <a
                  href="tel:18884195458"
                  className="btn--text btn--text--large"
                >
                  Phone
                </a>
              </li>
              <li>
                <a
                  href="mailto:comms@cloudkitchens.com"
                  className="btn--text btn--text--large"
                >
                  Press
                </a>
              </li>
            </ul>
          </div>
        </div>
        <hr />
        <div className="flex justify-between items-center pt-4">
          <div>
            <p className="caption caption--footer mb-0 flex items-center">
              <span className="caption--shown">
                &copy;{new Date().getFullYear()} City Storage Systems LLC.
              </span>
              <span className="caption--hidden color--grey-5 md:ml-4 md:pl-4">
                CloudKitchens&reg; is the registered trademark of <br />
                City Storage Systems LLC. All rights reserved.
              </span>
            </p>
          </div>
          <div className="hidden md:block">
            <ul className="flex justify-end">
              <li className="caption ml-12">
                <Link to="/terms-of-service">Terms&nbsp;of&nbsp;Service</Link>
              </li>
              <li className="caption ml-12">
                <Link to="/privacy-policy">Privacy&nbsp;Policy</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <CookieBar id="cookie-bar">
        <div className="container">
          <div className="flex justify-center items-center">
            <p className="caption color--grey-5 mb-0">
              We use <Link to="/cookies-policy">cookies</Link> to personalize
              content, analyze traffic, and for advertising.
            </p>
            <div className="hidden lg:block pt-2">
              <Link
                to="/cookies-policy"
                className="btn--text color--grey-5 ml-6 lg:ml-10"
              >
                Cookie Policy
              </Link>
            </div>
            <a
              href="#"
              className="cookie--accept btn--rounded btn--rounded--slim btn--border btn--border--grey-4 color--grey-6 ml-6 lg:ml-10 db"
            >
              Accept&nbsp;Cookies
            </a>
            <a href="#" className="cookie--close ml-6 lg:ml-10 hidden md:block">
              <Close />
            </a>
          </div>
        </div>
      </CookieBar>

      <div
        dangerouslySetInnerHTML={{
          __html: `<script>
                window.addEventListener('load', function() {
                const timer = setInterval(function() {
                if (document.querySelector('.form-2.is-active').innerText.includes("Thanks! Just a few more questions")) {
                gtag('event', 'conversion', {'send_to': 'AW-786056968/ESuLCInTk5ECEIiO6fYC'});
                clearInterval(timer);
            }
            }, 1000);
            });
            </script>`,
        }}
      />

      <div
        dangerouslySetInnerHTML={{
          __html: `<script type="text/javascript">
          window._mfq = window._mfq || [];
          (function() {
            var mf = document.createElement("script");
            mf.type = "text/javascript"; mf.defer = true;
            mf.src = "//cdn.mouseflow.com/projects/f65f0c17-7835-46d0-b0f3-9ccf92447ba1.js";
            document.getElementsByTagName("head")[0].appendChild(mf);
          })();
        </script>`,
        }}
      />
    </FooterDiv>
  );
}
