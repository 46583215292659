import { createGlobalStyle } from 'styled-components';
import { media } from './media';

const Buttons = createGlobalStyle`

    .btn--rounded {
        display: inline-block;
        padding: 13px 33px;
        font-weight: 600;
        font-size: 10px;
        line-height: 100%;
        letter-spacing: 0.04em;
        text-transform: uppercase;
        border-radius: 100px;
        border: 1px solid;
        &.btn--rounded--slim {
            padding: 8px 20px;
        }
        &.w-full {
            display: inline-block;
            width: 100%;
            text-align: center;
        }
    }
    
    .btn--bg {
        border: 0;
        &.btn--bg--white {
            --bg: var(--white);
            --bg-rgb: var(--white-rgb);
            --o: 0.95;
        }
        &.btn--bg--primary-dark {
            --bg: var(--primary-dark);
            --bg-rgb: var(--primary-dark-rgb);
            --o: 0.95;
        }
        &.btn--bg--primary {
            --bg: var(--primary);
            --bg-rgb: var(--primary-rgb);
            --o: 0.95;
        }
        &.btn--bg--secondary-dark {
            --bg: var(--secondary-dark);
            --bg-rgb: var(--secondary-dark-rgb);
            --o: 0.95;
        }
        &.btn--bg--secondary {
            --bg: var(--secondary);
            --bg-rgb: var(--secondary-rgb);
            --o: 0.95;
        }
        &.btn--bg--tertiary-dark {
            --bg: var(--tertiary-dark);
            --bg-rgb: var(--tertiary-dark-rgb);
            --o: 0.95;
        }
        &.btn--bg--tertiary {
            --bg: var(--tertiary);
            --bg-rgb: var(--tertiary-rgb);
            --o: 0.95;
        }
        &.btn--bg--grey-2 {
            --bg: var(--grey-2);
            --bg-rgb: var(--grey-2-rgb);
            --o: 0.95;
        }
        &.btn--bg--grey-3 {
            --bg: var(--grey-3);
            --bg-rgb: var(--grey-3-rgb);
            --o: 0.95;
        }
        background-color: var(--bg);
        &:hover, &:focus {
            background-color: rgba(var(--bg-rgb),var(--o));
        }
    }
            
    .btn--border {
        &.btn--border--primary-dark {
            --color: var(--primary-dark);
        }
        &.btn--border--primary {
            --color: var(--primary);
        }
        &.btn--border--secondary-dark {
            --color: var(--secondary-dark);
        }
        &.btn--border--secondary {
            --color: var(--secondary);
        }
        &.btn--border--tertiary-dark {
            --color: var(--tertiary-dark);
        }
        &.btn--border--tertiary {
            --color: var(--tertiary);
        }
        &.btn--border--grey-4 {
            --color: var(--grey-4);
        }
        background-color: transparent;
        border-color: var(--color);
        &:hover {
            border-color: var(--white);
            &.btn--border--grey-4 {
                border-color: var(--grey-6);
            }
        }
    }
    
    .btn--text {
        display: inline-block;  
        position: relative;
        overflow-x: hidden;
        padding: 4px 0 4px 0;
        font-weight: 600;
        font-size: 10px;
        line-height: 100%;
        letter-spacing: 0.04em;
        text-transform: uppercase;
        &.btn--text--large {
            margin: 0 0 5px;
            padding: 0 0 2px 0;
            font-size: 14px;
            font-weight: normal;
            line-height: 120%;
            letter-spacing: 0.01em;
            text-transform: inherit;
        }
        svg {
            position: absolute;
            top: 1px;
            right: 4px;
            transition: all .15s linear;
        }
        path {
            fill: currentColor;
        }
        &::before {
            position: absolute;
            bottom: 0;
            left: 0;
            content: '';
            width: 100%;
            height: 1px;
            transform: translateX(calc(-100% - .1rem));
            background: currentColor;
            animation: .25s linear 0s line-out forwards;
        }
        &:hover {
            background-position: top 2px right 2px;
            &::before {
                animation:.25s linear 0s line-in forwards
            }
            svg {
                right: 0;
            }
        }
    }
    .btn--text--svg {
        position: relative;
        padding-top: 7px;
        padding-right: 20px;
        svg {
            position: absolute;
            top: 12px;
            right: 4px;
            transition: all .15s linear;
        }
        path {
            fill: currentColor;
        }
        span {
            display: inline-block;  
            position: relative;
            overflow-x: hidden;
            padding: 4px 0 4px 0;
            font-weight: 600;
            font-size: 10px;
            line-height: 100%;
            letter-spacing: 0.04em;
            text-transform: uppercase;
            vertical-align: top;
            &::before {
                position: absolute;
                bottom: 0;
                left: 0;
                content: '';
                width: 100%;
                height: 1px;
                transform: translateX(calc(-100% - .1rem));
                background: currentColor;
                animation: .25s linear 0s line-out forwards;
            }
        }
        &:hover, &:focus {
            span {
                background-position: top 2px right 2px;
                &::before {
                    animation:.25s linear 0s line-in forwards
                }
            }
            svg {
                right: 0;
            }
        }
    }
        
    @keyframes line-in{
        0%{transform:translate3d(-101%,0,0)}
        to{transform:translateZ(0)}
    }
    
    @keyframes line-out{
        0%{transform:translateZ(0)}
        to{transform:translate3d(101%,0,0)}
    }
    
    .btn--text--play {
        display: flex;
        align-items: center;
        font-weight: 600;
        font-size: 10px;
        line-height: 100%;
        letter-spacing: 0.04em;
        text-transform: uppercase;
        span {
            display: flex;
            align-items: center;
            width: 18px;
            height: 18px;
            margin: 0 0 0 10px;
            &::before {
                position: absolute;
                content: '';
                width: 18px;
                height: 18px;
                border-radius: 100%;
                border: 1px solid currentColor;
                transition: all .15s linear;
            }
        }
        svg {
            margin: 0 0 0 7px;
        }
        path {
            fill: currentColor;
        }
        &:hover, &:focus {
            span {
                &::before {
                    transform: scale(1.1);
                }
            }
        }
    }
    
    .btn--box {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 50px;
        svg {
        width: 12px;
        }
        &:hover, &:focus {
            background-color: var(--primary-1-dark);
        }
        &.btn--box-grey {
            background-color: #EDECE6;
            path {
                fill: var(--grey-6);
            }
            &:hover, &:focus {
                background-color: var(--primary-1-dark);
                path {
                    fill: var(--white);
                }
            }
        }
    }
    
    .btn--slider {
        position: relative;
        display: flex;
        width: 100%;
        min-width: 280px;
        font-weight: 600;
        font-size: 10px;
        line-height: 100%;
        letter-spacing: 0.04em;
        text-transform: uppercase;
        box-shadow: inset 0 0 0 1px var(--grey-4);
        border-radius: 50px;
        color: var(--grey-5);
        .btn--slider-1, .btn--slider-2 {
            position: relative;
            z-index: 1;
            padding: 8px 0;
            width: 50%;
            text-align: center;
            transition: all 0.15s linear;
        }
        .btn--slider-2 {
            color: var(--white);
        }
        &::after {
            position: absolute;
            content: '';
            top: 0;
            left: 50%;
            width: 50%;
            height: 100%;
            background: var(--grey-4);
            border-radius: 50px;
            border: 1px solid var(--grey-4);
            transition: all 0.15s linear;
        }
        &.delivery, &.delivery-fade {
            .btn--slider-1 {
                color: var(--white);
            }
            .btn--slider-2 {
                color: var(--grey-5);
            }
            &::after {
                left: -1px;
                background: var(--primary-dark);
                border: 1px solid var(--primary-dark);
            }
        }
        &.secondary {
            &.delivery, &.delivery-fade {
                &::after {
                    background: var(--secondary);
                    border: 1px solid var(--secondary);
                }
            }
        }
    }
    
    .btn--circle {
        align-items: center;
        justify-content: center;
        width: 54px;
        height: 54px;
        border-radius: 100%;
        background-color: var(--grey-1);
        box-shadow: 0px 0px 34px rgba(0, 0, 0, 0.11);
        &:hover, &:focus {
            background-color: var(--white);
        }
    }
    
    .btn--compare {
        width: 140px;
        text-align: center;
        a {
            display: block;
            margin-bottom: 10px; 
            width: 100%;
        }
        .btn--bg.btn--bg--primary {
            border: 1px solid var(--primary);
        }
        .btn--bg.btn--bg--primary-dark {
            border: 1px solid var(--primary-dark);
        }
        .btn--bg.btn--bg--secondary {
            border: 1px solid var(--secondary);
        }
        &.brick {
            .btn--bg.btn--bg--primary-dark,
            .btn--bg.btn--bg--primary,
            .btn--bg.btn--bg--secondary {
                background: transparent;
                border: 1px solid var(--grey-4);
                color: var(--grey-5);
                &:hover, &:focus {
                    border-color: var(--grey-6);
                }
            }
            .btn--border.btn--border--grey-4 {
                background: var(--grey-4);
                border-color: var(--grey-4);
                color: var(--white);
            }
        }
    }
    .btn--compare--title {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;
        font-weight: 500;
        font-size: 16px;
        line-height: 125%;
        letter-spacing: 0.01em;
        svg {
            margin: 0 0 0 8px;
            vertical-align: middle;
        }
    }
    .btn--compare--small {
        margin-bottom: 20px;
        font-size: 10px;
        line-height: 145%;
        letter-spacing: 0.04em;
    }
    
    .btn--arrow--angle {
        display: block;
        position: relative;
        width: 100%;
        &.btn--arrow--angle--large {
            font-size: 20px;
            line-height: 110%;
            letter-spacing: -0.01em;
            @media ${media.mdUp} { 
                font-size: 24px;
            }
        }
        &:hover {
            svg {
                transform: translate(5px, -5px);
            }
        }
        span.small {
            display: block;
            margin: 2px 0 0;
        }
        svg {
            position: absolute;
            top: 8px;
            right: 5px;
            transition: all .15s linear;
        }
        .h6 {
            display: inline-block;
            text-transform: lowercase;
            &:first-letter {
                text-transform: uppercase;
            }
        }
    }
    
    .btn--card {
        width: 220px;
        @media ${media.lgUp} { 
            margin: 0 18px;
        }
        &.btn--card--large {
            width: 100%;
            margin: 0;
            .btn--card--image {
                width: 100%;
                height: 100%;
            }
        }
    }
    .btn--card--image {
        width: 220px;
        height: 260px;
        position: relative;
        overflow: hidden;
        cursor: pointer;
        &:hover, &:focus {
            .btn--card--bg {
                transform: scale(1.025);
            }
            .btn--card--play {
                background-color: var(--grey-1);
            }
        }

    }
    .btn--card--bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        transition: all .3s linear;
    }
    .btn--card--play {
        position: absolute;
        display: flex;
        z-index: 1;
        right: 0;
        bottom: 0;
        width: 50px;
        height: 50px;
        background-color: var(--grey-2);
        align-items: center;
        justify-content: center;
        transition: all .3s linear;
    }
    
    .btn--play {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 85px;
        height: 85px;
        margin-left: -5px;
        border-radius: 100%;
        svg {
            position: relative;
            z-index: 10;
            margin: 0 0 0 4px;
        }
        path {
            fill: var(--white);
        }
        &::after {
            position: absolute;
            z-index: 1;
            content: '';
            width: 85px;
            height: 85px;
            border-radius: 100%;
            background: currentColor;
            transform: scale(0.95);
            transition: all .15s linear;
            transform-origin: center;
        }
        &:hover, &:focus {
            &::after {
                transform: scale(1);
            }
        }
    }
    
    .btn--chat {
        position: fixed;
        z-index: 10;
        bottom: 20px;
        right: 20px;
    }
`;

export default Buttons;
