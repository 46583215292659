const size = {
  sm: '375px',
  md: '720px',
  lg: '1025px',
  xl: '1280px',
};
export const media = {
  smUp: `(min-width: ${size.sm})`,
  mdUp: `(min-width: ${size.md})`,
  lgUp: `(min-width: ${size.lg})`,
  xlUp: `(min-width: ${size.xl})`,
};
