import React from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import Layout from './src/components/Layout';

import './src/css/index.css'; // Tailwind

gsap.registerPlugin(ScrollTrigger);

export function wrapPageElement({ element, props }) {
  return <Layout {...props}>{element}</Layout>;
}
