import React, { Component } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { gsap } from 'gsap';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import logoCloudRetail from '../assets/images/cloudretail.svg';
import { media } from '../styles/media';

const NavStyles = styled.nav`
  padding-top: 20px;
  padding-bottom: 20px;
  gap: 0;
  align-items: center;
  height: 68px;
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    align-items: center;
  }
  .nav--links {
    transition: all 0.3s ease-in;
    li {
      display: inherit;
      padding: 0 10px 0;
      @media ${media.xlUp} {
        padding: 0 30px 0 0;
      }
    }
  }
  .nav--hide {
    transition: all 0.3s ease-in;
  }
  .nav--cta {
    justify-content: flex-end;
    li:first-child {
      @media ${media.lgUp} {
        //padding-right: 30px;
      }
    }
  }
  .nav--icon--menu {
    padding-left: 30px;
  }
  .icon--menu {
    width: 12px;
    display: block;
    .line-1 {
      transform-origin: right center;
      transition: all 0.1s linear;
    }
    .line-2 {
      transform-origin: right bottom;
      transition: all 0.1s linear;
    }
    .line-3 {
      transform-origin: right top;
      transition: all 0.1s linear;
    }
    &:hover {
      .line-2 {
        transform: translate(-6px, 0);
        width: 14px;
      }
      .line-3 {
        transform: translate(-6px, 0);
        width: 14px;
      }
    }
  }
  .is-active {
    .icon--menu {
      .line-1 {
        width: 0;
      }
      .line-2 {
        transform: rotate(45deg) translate(-6px, 2px);
        width: 12px;
      }
      .line-3 {
        transform: rotate(-45deg) translate(-6px, -1px);
        width: 12px;
      }
    }
  }
`;

const NavDropdown = styled.div`
  position: fixed;
  top: 0;
  z-index: 19;
  width: 100%;
  height: 100vh;
  transform: translate(0, -150%);
  transition: all 0.4s cubic-bezier(0.4, 0, 1, 1); // Ease In
  @media ${media.lgUp} {
    height: auto;
  }
  .container {
    height: 90%;
    @media ${media.mdUp} {
      height: 100%;
    }
    @media ${media.lgUp} {
      height: auto;
    }
    @media screen and ${media.mdUp} and (max-height: 759px) {
      height: auto;
    }
  }
  &.is-active {
    transform: translate(0, 0);
    transition: all 0.5s cubic-bezier(0, 0, 0.2, 1); // Ease Out
  }
  .nav--contact {
    a {
      font-size: 14px;
      color: var(--grey-5);
      &:hover,
      &:focus {
        opacity: 0.7;
      }
    }
  }
  .nav--large {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      margin-bottom: 10px;
      @media ${media.mdUp} {
        margin-bottom: 20px;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
    a {
      display: inline-block;
      padding: 0 0 10px 0;
      position: relative;
      font-size: 34px;
      line-height: 80%;
      letter-spacing: -0.01em;
      color: var(--grey-6);
      overflow: hidden;
      @media ${media.mdUp} {
        font-size: 44px;
      }
      &::before {
        position: absolute;
        bottom: 0;
        left: 0;
        content: '';
        width: 100%;
        height: 2px;
        background: var(--grey-6);
        transition: all 0.15s linear;
        transform: translateX(calc(-100% - 0.1rem));
        animation: 0.25s linear 0s line-out forwards;
      }
      &:hover,
      &:focus {
        &::before {
          animation: 0.25s linear 0s line-in forwards;
        }
      }
    }
  }
  .hide-height {
    display: none;
    @media screen and ${media.mdUp} and (min-height: 760px) {
      display: block;
    }
  }
  .height-margin {
    @media screen and ${media.mdUp} and (max-height: 759px) {
      margin-top: 0;
      padding-top: 0;
    }
  }
`;

class NavRetail extends Component {
  constructor(props) {
    super(props);
    this.state = { active: false };
  }

  componentDidMount() {
    const fadeElements = gsap.timeline();
    fadeElements.to('.nav--holder--retail', {
      opacity: 1,
      delay: 0.5,
    });

    const navElements = gsap.timeline();
    navElements
      .from('.nav--large li', {
        duration: 0.7,
        opacity: '0',
        delay: 0.4,
        y: '-7',
        stagger: {
          amount: '0.5',
        },
      })
      .from(
        '.fade-1',
        {
          opacity: '0',
          y: '-7',
          duration: 0.3,
          delay: 0.4,
        },
        0
      );

    this.animationNav = navElements;

    const offerLinks = document.querySelectorAll(`a[href='#what-we-offer']`);
    const offer = document.querySelector(`#what-we-offer`);

    offerLinks.forEach((link) => {
      link.addEventListener('click', function (e) {
        e.preventDefault();
        offer.scrollIntoView({
          behavior: 'smooth',
        });
      });
    });

    const worksLinks = document.querySelectorAll(`a[href='#how-it-works']`);
    const works = document.querySelector(`#how-it-works`);

    worksLinks.forEach((link) => {
      link.addEventListener('click', function (e) {
        e.preventDefault();
        works.scrollIntoView({
          behavior: 'smooth',
        });
      });
    });
  }

  toggleModal = () => {
    const html = document.querySelector('html');
    const wrapper = document.querySelector('.wrapper');
    const modalBg = document.querySelector('.modal--bg');
    const htmlActive = document.querySelector('html.is-scroll-disabled-nav');
    if (htmlActive) {
      this.setState({ active: false });
      modalBg.classList.remove('is-active');
      html.classList.remove('is-scroll-disabled-nav');
      const scrollY = wrapper.style.top;
      wrapper.style.top = '';
      window.scrollTo(0, parseInt(scrollY || '0') * -1);
    } else {
      this.setState({ active: true });
      this.animationNav.restart().play();
      modalBg.classList.add('is-active');
      setTimeout(function () {
        wrapper.style.top = `-${document.documentElement.scrollTop}px`;
        html.classList.add('is-scroll-disabled-nav');
      }, 500);
    }
  };

  handleContact = () => {
    this.toggleModal();
    const contact = document.querySelector('#contact');
    contact.scrollIntoView({
      behavior: 'smooth',
    });
  };

  render() {
    return (
      <>
        <div
          className={`nav--holder--retail bg--primary ${
            this.state.active ? 'is-active' : ''
          }`}
        >
          <NavStyles className="container grid grid-cols-4 md:grid-cols-8 lg:grid-cols-12">
            <div className="col-span-2 md:col-span-2 lg:col-span-6">
              <AniLink to="/" cover direction="up" duration={1} bg="#E4E2D4">
                <img src={logoCloudRetail} alt="CloudRetail" className="db" />
              </AniLink>
            </div>

            <ul className="nav--links hidden md:flex md:justify-center lg:justify-start col-span-3">
              <li>
                <a href="https://retail.cloudkitchens.com/#what-we-offer" className="btn--text color--grey-3">
                  What we offer
                </a>
              </li>
              <li>
                <a href="https://retail.cloudkitchens.com/#how-it-works" className="btn--text color--grey-3">
                  How it works
                </a>
              </li>
            </ul>

            <ul className="nav--cta flex col-span-2 lg:col-span-3">
              <li className="nav--hide">
                <a
                  href="https://retail.cloudkitchens.com/#contact"
                  className="btn--rounded btn--rounded--slim btn--bg btn--bg--secondary btn--border--secondary color--grey-1 db"
                >
                  Get Started
                </a>
              </li>
            </ul>
          </NavStyles>
        </div>
        <div>
          <NavDropdown
            className={`pt-20 md:pt-24 md:pb-16 lg:pb-24 bg--grey-2 ${
              this.state.active ? 'is-active' : ''
            }`}
          >
            <div className="container grid grid-cols-1 md:grid-cols-8 lg:grid-cols-12 align-end flex flex-col">
              <div className="hidden md:block order-2 lg:order-1 col-span-8 lg:col-span-7 md:mt-20 lg:mt-0 fade-1 height-margin">
                <p className="eyebrow color--grey-6 mb-6 hide-height">
                  Start a conversation
                </p>
                <p className="w-27 small color--grey-5 hide-height">
                  Get in touch to start cooking for delivery with CloudKitchens.
                  Want a tour, or just have questions? We’re here for that too.
                </p>
                <div className="nav--contact flex mt-12">
                  <div className="w-20">
                    <p className="color--grey-6 mb-0 md">Phone</p>
                    <p className="small">
                      <a
                        href="tel:18884195458"
                        className="menu--link color--grey-5"
                      >
                        +1 888-419-5458
                      </a>
                    </p>
                  </div>
                  <div>
                    <p className="color--grey-6 mb-0 md">Email</p>
                    <p className="small mb-0">
                      <a
                        href="mailto:sales@cloudkitchens.com"
                        className="menu--link color--grey-5"
                      >
                        sales@cloudkitchens.com
                      </a>
                    </p>
                  </div>
                </div>
              </div>
              <nav className="order-1 lg:order-2 col-span-5 lg:pt-32">
                <ul className="nav--large">
                  <li>
                    <AniLink
                      cover
                      direction="up"
                      duration={1}
                      bg="#E4E2D4"
                      to="/commercial-kitchens/"
                      onClick={this.toggleModal}
                    >
                      Kitchens
                    </AniLink>
                  </li>
                  <li>
                    <AniLink
                      cover
                      direction="up"
                      duration={1}
                      bg="#E4E2D4"
                      to="/restaurant-technology/"
                      onClick={this.toggleModal}
                    >
                      Technology
                    </AniLink>
                  </li>
                  <li>
                    <AniLink
                      cover
                      direction="up"
                      duration={1}
                      bg="#E4E2D4"
                      to="/partners/"
                      onClick={this.toggleModal}
                    >
                      Partners
                    </AniLink>
                  </li>
                  <li>
                    <AniLink
                      cover
                      direction="up"
                      duration={1}
                      bg="#E4E2D4"
                      to="/blog/"
                      onClick={this.toggleModal}
                    >
                      Blog
                    </AniLink>
                  </li>
                  <li>
                    <AniLink
                      cover
                      direction="up"
                      duration={1}
                      bg="#E4E2D4"
                      to="/careers"
                      onClick={this.toggleModal}
                    >
                      Careers
                    </AniLink>
                  </li>
                </ul>
              </nav>
              <div className="pt-6 md:pt-20 md:pt-24 order-3 col-span-4 lg:col-span-7 fade-1 lg:hidden height-margin">
                <div className="flex md:hidden nav--contact">
                  <div>
                    <p className="color--grey-6 mb-0">
                      Phone
                      <br />
                      <a
                        href="tel:18007169743"
                        className="menu--link color--grey-5"
                      >
                        +1 800-716-9743
                      </a>
                    </p>
                    <p className="color--grey-6 mb-0 mt-2 md:mt-6">
                      Email
                      <br />
                      <a
                        href="mailto:sales@cloudkitchens.com"
                        className="menu--link color--grey-5"
                      >
                        sales@cloudkitchens.com
                      </a>
                    </p>
                  </div>
                </div>
                <p className="caption mt-10 md:mt-0 mb-0">
                  CloudKitchens &copy;{new Date().getFullYear()}. All Rights
                  Reserved.
                </p>
              </div>
              <div className="pt-24 hidden md:block lg:hidden order-4 col-span-4 lg:col-span-5 fade-1">
                <ul className="flex justify-end">
                  <li className="caption ml-12">
                    <Link to="/terms-of-service">Terms of service</Link>
                  </li>
                </ul>
              </div>
            </div>
          </NavDropdown>
        </div>
        <div className="modal--bg" onClick={this.toggleModal} />
      </>
    );
  }
}
export default NavRetail;
