import React, { Component } from 'react';
import styled from 'styled-components';
import Nav from './Nav';
import Footer from './Footer';

import GlobalStyles from '../styles/GlobalStyles';
import Typography from '../styles/Typography';
import Grid from '../styles/Grid';
import Buttons from '../styles/Buttons';

import Form from '../styles/Form';
import NavRetail from './NavRetail';
import Retail from '../styles/Retail';
import FooterRetail from './FooterRetail';

const ContentStyles = styled.main`
  margin-top: 68px;
`;

class Layout extends Component {
  componentDidMount() {}

  componentDidUpdate() {}

  render() {
    const { children } = this.props;
    const url = typeof window !== 'undefined' ? window.location.pathname : '';
    console.log(url);
    return (
      <>
        <GlobalStyles />
        <Typography />
        <Grid />
        <Buttons />
        <Form />
        <Retail />
        <div className="wrapper">
          <NavRetail url={url} />
          <ContentStyles>
            {children}
            <FooterRetail />
          </ContentStyles>
        </div>
      </>
    );
  }
}

export default Layout;
