import { createGlobalStyle } from 'styled-components';
import { media } from './media';

const Form = createGlobalStyle`
  .form--element {
    margin: 0 0 10px;
    padding: 0 0 10px;
    position: relative;
    width: 100%;
    border-bottom: 1px solid var(--grey-3);
    @media ${media.mdUp} {
       margin: 0 0 15px;
    }
    &:not([class*="form--element--"]){
    overflow: hidden;
    }
    &.secondary {
        border-color: rgba(var(--grey-3-rgb),0.7);
    }
    &.focus {
      .form--element--label {
        opacity: 1;
      }
      &.error {
        .form--element--label {
          color: var(--orange-dark);
        }
      }
      .form--element--placeholder {
        opacity: 0;
      }
    }
    &.active {
      border-color: transparent;
      border-bottom-width: 2px;
      .form--element--text {
        width: auto;
        padding: 0 0 0 4px;
        @media ${media.mdUp} {
          padding: 0;
        }
      }
    }
    &.done {
        border-color: var(--grey-3);
        border-bottom-width: 1px;
        .form--element--border {
            border-color: transparent;
        }
    }
    &.is-active {
      border-bottom-width: 1px;
      .form--element--label {
        opacity: 1;
      }
    }
  }
  .secondary {
    .form--element {
        &.done {
            .form--element--border {
                border-color: var(--grey-2);
            }
        }   
    }
  }
  .form--element--label {
    display: block;
    margin: 0 0 6px;
    font-size: 10px;
    font-weight: 500;
    line-height: 140%;
    letter-spacing: 0.01em;
    opacity: 0;
    transition: all 0.15s linear;
  }
  .form--element--placeholder,
  .form--element--text {
    line-height: 120%;
    font-size: 18px;
    letter-spacing: -0.01em;
    overflow: hidden;
    width: 100%;
    white-space: nowrap;
    @media ${media.mdUp} {
      font-size: 24px;
    }
  }
  .form--element--text {
    position: absolute;
    left: 0;
    z-index: 1;
    width: 100%;
    &:focus {
      outline: 0;
    }
    &.color--white {
      color: var(--white);
    }
  }
  .form--element--placeholder {
    color: var(--grey-4);
    transition: all 0.15s linear;
    &.secondary {
      color: rgba(var(--grey-3-rgb),0.7);
    }
  }
  .form--element--border {
    position: absolute;
    bottom: 0;
    width: 0;
    border-bottom: 2px solid var(--grey-6);
    transition: all 0.15s linear;
    &.color--white {
      border-color: rgba(var(--grey-3-rgb),0.7);
    }
  }
  .form--element--select {
    border: 0 !important;
    padding: 0 !important;
    appearance: none;
    width: 100%;
    line-height: 1;
    font-size: 18px !important;
    letter-spacing: -0.01em;
    color: var(--grey-4) !important;
    @media ${media.mdUp} {
      font-size: 24px !important;
    }
    &.secondary {
      color: var(--secondary-light) !important;
    }
    &:focus {
      outline: 0;
    }
    optgroup {
      font-size: 16px;
      color: var(--black);
    }
    input {
      line-height: 20px;
    }
  }
  
  .react-select__control {
    border: 0 !important;
    background: none !important;
    min-height: auto !important;
    &:focus, &:focus-within {
      box-shadow: 0 0 0 !important;
      outline: 0 !important;
    }
  }
  .react-select__value-container {
    padding: 0 !important;
  }
  .react-select__indicator {
    background-image: url("data:image/svg+xml,%3Csvg width='12' height='7' viewBox='0 0 12 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 1.00312L10.9727 -4.49057e-08L6.7541 4.23053L5.98907 5.06661L5.22404 4.23053L1.00546 0.0218074L-4.48008e-08 1.02492L5.98907 7L12 1.00312Z' fill='%23B5B3AB'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: center;
    svg {
      display: none;
    }
  }
  .react-select__control--menu-is-open {
    .react-select__indicator {
      transform: rotate(180deg);    
    }
  }
  .react-select__indicator-separator {
    display: none;
  }
  .react-select__placeholder {
    color: var(--grey-4) !important;
  }
  .react-select__single-value {
    color: var(--grey-6) !important;
  }
  .react-select__dropdown-indicator:hover {
    color: var(--grey-4) !important;
  }
  .react-select__dropdown-indicator {
    svg {
      color: var(--grey-4) !important;
    }
  }
  .react-select__control--menu-is-open {
    .react-select__dropdown-indicator {
      color: var(--grey-4) !important;
    }
  }
  .react-select__input {
    div {
      overflow: hidden !important;
    }
  }
  .react-select__group-heading {
    padding-left: 0 !important;
    padding-bottom: 10px;
    margin-bottom: 0 !important;
    font-size: 10px !important;
    color: var(--grey-4) !important;
    //border-bottom: 2px solid var(--grey-5);
  }
  .react-select__menu {
    z-index: 100 !important;
    max-height: 270px !important;
    border-radius: 0 !important;
    border-color: var(--grey-3) !important;
    background: var(--grey-2) !important;
  }
  .react-select__menu-list {
    padding: 5px 15px !important;
    max-height: 270px !important;
  }
  .react-select__option {
    font-size: 18px !important;
    padding: 10px 0 !important;
    border-bottom: 1px solid var(--grey-3) !important;
    color: var(--grey-4) !important;
    /*text-transform: lowercase;
    &:first-letter {
      text-transform: uppercase;
    }*/
    @media ${media.mdUp} {
      font-size: 24px !important;
    }
    &:last-of-type {
      border: 0 !important;
    }
    &:hover {
      color: var(--grey-5) !important;
      background: none !important;
    }
    &.react-select__option--is-selected {
      color: var(--grey-6) !important;
      background: none !important;
    }
    &.react-select__option--is-focused {
      background: none !important;
    }
  }
  .bg--primary:not(.bg--retail) {
    .react-select__placeholder {
      color: rgba(var(--grey-3-rgb),0.7) !important;
    }
    .react-select__single-value {
      color: var(--white) !important;
    }
    .react-select__indicator {
      color: var(--white) !important;
    }
    .react-select__dropdown-indicator:hover {
      color: rgba(var(--grey-3-rgb),0.7) !important;
    }
    .react-select__dropdown-indicator {
      svg {
        color: rgba(var(--grey-3-rgb),0.7) !important;
      }
    }
    .react-select__control--menu-is-open {
      .react-select__dropdown-indicator {
        color: rgba(var(--grey-3-rgb),0.7) !important;
      }
    }
    .react-select__indicator {
      background-image: url("data:image/svg+xml,%3Csvg width='12' height='7' viewBox='0 0 12 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 1.00312L10.9727 -4.49057e-08L6.7541 4.23053L5.98907 5.06661L5.22404 4.23053L1.00546 0.0218074L-4.48008e-08 1.02492L5.98907 7L12 1.00312Z' fill='%23F2BAB3'/%3E%3C/svg%3E%0A");
    }
    &.bg--retail-contact {
        .react-select__indicator {
      background-image: url("data:image/svg+xml,%3Csvg width='12' height='7' viewBox='0 0 12 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 1.00312L10.9727 -4.49057e-08L6.7541 4.23053L5.98907 5.06661L5.22404 4.23053L1.00546 0.0218074L-4.48008e-08 1.02492L5.98907 7L12 1.00312Z' fill='%23E4E2D4'/%3E%3C/svg%3E%0A");
    }
    }
  }
    
  @supports(-webkit-appearance: none) or (-moz-appearance: none) {
  input[type='checkbox'],
  input[type='radio'] {
    --active: var(--primary);
    --active-inner: var(--grey-2);
    --focus: 2px rgba(39, 94, 254, .3);
    --border: var(--grey-4);
    --border-hover: #275EFE;
    --background: var(--grey-2);
    --disabled: #F6F8FF;
    --disabled-inner: #E1E6F9;
    -webkit-appearance: none;
    -moz-appearance: none;
    margin: 2px 0 0;
    height: 12px;
    outline: none;
    display: inline-block;
    vertical-align: top;
    position: relative;
    cursor: pointer;
    border: 1px solid var(--border);
    background: var(--b, var(--background));
    transition: background .3s, border-color .3s, box-shadow .2s;
    &:after {
      content: '';
      display: block;
      left: 0;
      top: 0;
      position: absolute;
      transition: transform var(--d-t, .3s) var(--d-t-e, ease), opacity var(--d-o, .2s);
    }
    &:checked {
      --b: var(--active);
      --bc: var(--active);
      --d-o: .3s;
      --d-t: .6s;
      --d-t-e: cubic-bezier(.2, .85, .32, 1.2);
    }
    &:disabled {
      --b: var(--disabled);
      cursor: not-allowed;
      opacity: .9;
      &:checked {
        --b: var(--disabled-inner);
        --bc: var(--border);
      }
      & + label {
        cursor: not-allowed;
      }
    }
    &:hover {
      &:not(:checked) {
        &:not(:disabled) {
          --bc: var(--border-hover);
        }
      }
    }
    &:focus {
      //box-shadow: 0 0 0 var(--focus);
    }
    &:not(.switch) {
      width: 12px;
      &:after {
        opacity: var(--o, 0);
      }
      &:checked {
        --o: 1;
      }
    }
    & + label {
      font-size: 16px;
      line-height: 1;
      display: inline-block;
      vertical-align: top;
      cursor: pointer;
      margin-left: 10px;
    }
  }
  input[type='checkbox'] {
    &:not(.switch) {
      &:after {
        width: 6px;
        height: 6px;
        top: 2px;
        left: 2px;
        background: var(--active-inner);
        opacity: 1;
        transform: scale(var(--s, .7));
      }
      &:checked {
        --r: 43deg;
        --active-inner: #fff;
        border-color: var(--active);
      }
    }
  }
  input[type='radio'] {
    border-radius: 50%;
    &:after {
      width: 8px;
      height: 8px;
      left: 1px;
      top: 1px;
      border-radius: 50%;
      background: var(--active-inner);
      opacity: 0;
      transform: scale(var(--s, .7));
    }
    &:checked {
      --s: .5;
      border-color: var(--active);
    }
  }
  .secondary {
    input[type='checkbox'],
    input[type='radio'] {
    --active: var(--primary-1-dark);
    --border: rgba(var(--grey-3-rgb),0.7);
    --background: var(--primary);
    --active-inner: var(--primary);
      & + label {
        color: rgba(var(--grey-3-rgb),0.7);
      }
      &:checked {
        --active-inner: var(--white);
      }
    }
    .react-select__input {
      color: var(--white) !important;
    }
  }
  .bg--primary-dark {
    .secondary {
            input[type='checkbox'],
            input[type='radio'] {
                --active: var(--primary-light);
                --border: rgba(var(--grey-3-rgb),0.7);
                --background: var(--primary-dark);
                --active-inner: var(--primary-dark);
        }
      }
  }
  .form--secondary {
    input[type='checkbox'],
    input[type='radio'] {
    --active: var(--secondary-dark);
    --border: var(--secondary-light);
    }
  }
  .form--tertiary {
    input[type='checkbox'],
    input[type='radio'] {
    --active: var(--tertiary-dark);
    --border: var(--tertiary-light);
    }
  }
  
  .form-1,
  .form-2,
  .form-3 {
    //min-height: 650px;
    display: none;
    @media ${media.lgUp} {
      //min-height: 900px;
    }
    &.is-active {
      display: block;
    }
  }
}

`;

export default Form;
